import getConfig from 'next/config'

const { TAPFILIATE_KEY } = getConfig().publicRuntimeConfig

export const sendTracker = (
  event = 'event',
  eventCategory,
  eventAction,
  eventLabel,
  skipFacebook = false,
  eventValue = 1,
  isCustom = false
) => {

  // Load Datalayer and FBQ
  const { dataLayer, fbq } = window

  dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue
  })

  if (!skipFacebook && fbq) {
    if (!isCustom) { 
      fbq('track', 'Lead', {
        content_category: eventAction,
        contents: eventLabel
      })
    } else {
      fbq('track', 'Lead', {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      })
    }
  }
}


/**
 * Object {
  * event,
  * eventCategory,
  * eventAction,
  * eventLabel,
  * insuranceBrand,
  * scheduledDay,
  * scheduledTime
 * }
 */
export function sendDataLayerTracker(props) {
  window.dataLayer.push(props);
}

export const sendTrackerCallMeBack = (
  event = 'call-me-back',
  eventCategory,
  eventAction,
  eventLabel,
  insuranceBrand,
  scheduledDay,
  scheduledTime
) => {
  window.dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel,
    insuranceBrand,
    scheduledDay,
    scheduledTime
  })
}

export const sendTapfiliate = async phone => {
  await tap('getTrackingId', null, async tracking_id => {
    await fetch('https://api.tapfiliate.com/1.6/customers/', {
      method: 'POST',
      body: JSON.stringify({
        tracking_id,
        customer_id: phone,
        status: 'lead'
      }),
      headers: {
        'Api-key': TAPFILIATE_KEY,
        'content-type': 'application/json'
      }
    })
  })
}
