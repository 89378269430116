/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

export const Avatar = ({
  loading,
  className,
  src,
  alt,
  height,
  width,
  left,
  right,
  onClick,
  maxHeight,
  maxWidth,
  style,
}) => (
    <img
      src={src}
      className={className ? `${className} avatar` : 'avatar'}
      alt={alt || 'avatar'}
      loading={loading || 'auto'}
      style={{
        ...style,
        display: 'inline-flex',
        ...(maxHeight ? { maxHeight } : { height: height || '30px' }),
        ...(maxWidth ? { maxWidth } : { width: width || '30px' }),
        left: left,
        right: right,
      }}
      onClick={onClick}
      />
  );

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
  loading: PropTypes.string,
};

Avatar.defaultProps = {};

export default Avatar;
